@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "./breadcrumbs__typography";

.page-breadcrumbs {
    align-items: center;
    display: flex;
    min-height: 56px;
    padding: 0 10px;
    width: 100%;

    &--bottom-margin {
        margin-bottom: 30px;
    }
}

.experience-assets-breadcrumb {
    display: inline;
}

@import "../../../../../../../../app_core_page_designer/cartridge/client/default/scss/experience/components/layouts/breadcrumbs";
@import "../../../variables";
@import "../../../utilities/unit";

.page-breadcrumbs {
    .experience-breadcrumbsList,
    .experience-assets-breadcrumb,
    .page-breadcrumbs-link {
        display: inline-block;
    }

    .experience-assets-breadcrumb:not(:first-of-type) {
        .page-breadcrumbs-link img {
            display: none;
        }

        &:last-of-type {
            .breadcrumb-divider {
                display: none;
            }
        }
    }

    .breadcrumb-divider {
        margin: rem-calc(0 5);
    }
}

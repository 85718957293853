@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";

.page-breadcrumbs {
    line-height: 20px;
}

.page-breadcrumbs-link {
    font-family: var(--font-primary);
    font-size: var(--text-xs);
    font-weight: normal;
    line-height: 20px;

    &.active {
        color: $brand-black;
        font-size: var(--text-xs);
        line-height: 20px;
    }
}

:root {
  --skin-banner-background-color-1: transparent;
  --skin-menu-color-1: transparent;
  --skin-menu-color-1-invert: transparent;
  --skin-primary-color-1: #357793;
  --color-grey1: #f3f3f3;
}

:root {
  --skin-banner-background-color-1: transparent;
  --skin-menu-color-1: transparent;
  --skin-menu-color-1-invert: transparent;
  --skin-primary-color-1: #357793;
  --color-grey1: #f3f3f3;
}

.page-breadcrumbs {
  line-height: 20px;
}

.page-breadcrumbs-link {
  font-family: var(--font-primary);
  font-size: var(--text-xs);
  font-weight: normal;
  line-height: 20px;
}
.page-breadcrumbs-link.active {
  color: #1E1E1E;
  font-size: var(--text-xs);
  line-height: 20px;
}

.page-breadcrumbs {
  align-items: center;
  display: flex;
  min-height: 56px;
  padding: 0 10px;
  width: 100%;
}
.page-breadcrumbs--bottom-margin {
  margin-bottom: 30px;
}

.experience-assets-breadcrumb {
  display: inline;
}

:root {
  --skin-banner-background-color-1: transparent;
  --skin-menu-color-1: transparent;
  --skin-menu-color-1-invert: transparent;
  --skin-primary-color-1: #357793;
  --color-grey1: #f3f3f3;
}

/*------------------------------------*\
        #UNIT
\*------------------------------------*/
.fs-10 {
  font-size: 0.625rem;
}

.fs-11 {
  font-size: 0.6875rem;
}

.fs-12 {
  font-size: 0.75rem;
}

.fs-13 {
  font-size: 0.8125rem;
}

.fs-14 {
  font-size: 0.875rem;
}

.fs-15 {
  font-size: 0.9375rem;
}

.fs-16 {
  font-size: 1rem;
}

.fs-17 {
  font-size: 1.0625rem;
}

.fs-18 {
  font-size: 1.125rem;
}

.fs-19 {
  font-size: 1.1875rem;
}

.fs-20 {
  font-size: 1.25rem;
}

.fs-21 {
  font-size: 1.3125rem;
}

.fs-22 {
  font-size: 1.375rem;
}

.fs-23 {
  font-size: 1.4375rem;
}

.fs-24 {
  font-size: 1.5rem;
}

.fs-25 {
  font-size: 1.5625rem;
}

.fs-26 {
  font-size: 1.625rem;
}

.fs-28 {
  font-size: 1.75rem;
}

.fs-32 {
  font-size: 2rem;
}

.fs-36 {
  font-size: 2.25rem;
}

.fs-40 {
  font-size: 2.5rem;
}

.fs-44 {
  font-size: 2.75rem;
}

.fs-48 {
  font-size: 3rem;
}

.fs-50 {
  font-size: 3.125rem;
}

.fs-54 {
  font-size: 3.375rem;
}

.fs-56 {
  font-size: 3.5rem;
}

.fs-60 {
  font-size: 3.75rem;
}

.fs-64 {
  font-size: 4rem;
}

@media (min-width: 544px) {
  .fs-sm-10 {
    font-size: 0.625rem;
  }
  .fs-sm-11 {
    font-size: 0.6875rem;
  }
  .fs-sm-12 {
    font-size: 0.75rem;
  }
  .fs-sm-13 {
    font-size: 0.8125rem;
  }
  .fs-sm-14 {
    font-size: 0.875rem;
  }
  .fs-sm-15 {
    font-size: 0.9375rem;
  }
  .fs-sm-16 {
    font-size: 1rem;
  }
  .fs-sm-17 {
    font-size: 1.0625rem;
  }
  .fs-sm-18 {
    font-size: 1.125rem;
  }
  .fs-sm-19 {
    font-size: 1.1875rem;
  }
  .fs-sm-20 {
    font-size: 1.25rem;
  }
  .fs-sm-21 {
    font-size: 1.3125rem;
  }
  .fs-sm-22 {
    font-size: 1.375rem;
  }
  .fs-sm-23 {
    font-size: 1.4375rem;
  }
  .fs-sm-24 {
    font-size: 1.5rem;
  }
  .fs-sm-25 {
    font-size: 1.5625rem;
  }
  .fs-sm-26 {
    font-size: 1.625rem;
  }
  .fs-sm-28 {
    font-size: 1.75rem;
  }
  .fs-sm-32 {
    font-size: 2rem;
  }
  .fs-sm-36 {
    font-size: 2.25rem;
  }
  .fs-sm-40 {
    font-size: 2.5rem;
  }
  .fs-sm-44 {
    font-size: 2.75rem;
  }
  .fs-sm-48 {
    font-size: 3rem;
  }
  .fs-sm-50 {
    font-size: 3.125rem;
  }
  .fs-sm-54 {
    font-size: 3.375rem;
  }
  .fs-sm-56 {
    font-size: 3.5rem;
  }
  .fs-sm-60 {
    font-size: 3.75rem;
  }
  .fs-sm-64 {
    font-size: 4rem;
  }
}
@media (min-width: 769px) {
  .fs-md-10 {
    font-size: 0.625rem;
  }
  .fs-md-11 {
    font-size: 0.6875rem;
  }
  .fs-md-12 {
    font-size: 0.75rem;
  }
  .fs-md-13 {
    font-size: 0.8125rem;
  }
  .fs-md-14 {
    font-size: 0.875rem;
  }
  .fs-md-15 {
    font-size: 0.9375rem;
  }
  .fs-md-16 {
    font-size: 1rem;
  }
  .fs-md-17 {
    font-size: 1.0625rem;
  }
  .fs-md-18 {
    font-size: 1.125rem;
  }
  .fs-md-19 {
    font-size: 1.1875rem;
  }
  .fs-md-20 {
    font-size: 1.25rem;
  }
  .fs-md-21 {
    font-size: 1.3125rem;
  }
  .fs-md-22 {
    font-size: 1.375rem;
  }
  .fs-md-23 {
    font-size: 1.4375rem;
  }
  .fs-md-24 {
    font-size: 1.5rem;
  }
  .fs-md-25 {
    font-size: 1.5625rem;
  }
  .fs-md-26 {
    font-size: 1.625rem;
  }
  .fs-md-28 {
    font-size: 1.75rem;
  }
  .fs-md-32 {
    font-size: 2rem;
  }
  .fs-md-36 {
    font-size: 2.25rem;
  }
  .fs-md-40 {
    font-size: 2.5rem;
  }
  .fs-md-44 {
    font-size: 2.75rem;
  }
  .fs-md-48 {
    font-size: 3rem;
  }
  .fs-md-50 {
    font-size: 3.125rem;
  }
  .fs-md-54 {
    font-size: 3.375rem;
  }
  .fs-md-56 {
    font-size: 3.5rem;
  }
  .fs-md-60 {
    font-size: 3.75rem;
  }
  .fs-md-64 {
    font-size: 4rem;
  }
}
@media (min-width: 992px) {
  .fs-lg-10 {
    font-size: 0.625rem;
  }
  .fs-lg-11 {
    font-size: 0.6875rem;
  }
  .fs-lg-12 {
    font-size: 0.75rem;
  }
  .fs-lg-13 {
    font-size: 0.8125rem;
  }
  .fs-lg-14 {
    font-size: 0.875rem;
  }
  .fs-lg-15 {
    font-size: 0.9375rem;
  }
  .fs-lg-16 {
    font-size: 1rem;
  }
  .fs-lg-17 {
    font-size: 1.0625rem;
  }
  .fs-lg-18 {
    font-size: 1.125rem;
  }
  .fs-lg-19 {
    font-size: 1.1875rem;
  }
  .fs-lg-20 {
    font-size: 1.25rem;
  }
  .fs-lg-21 {
    font-size: 1.3125rem;
  }
  .fs-lg-22 {
    font-size: 1.375rem;
  }
  .fs-lg-23 {
    font-size: 1.4375rem;
  }
  .fs-lg-24 {
    font-size: 1.5rem;
  }
  .fs-lg-25 {
    font-size: 1.5625rem;
  }
  .fs-lg-26 {
    font-size: 1.625rem;
  }
  .fs-lg-28 {
    font-size: 1.75rem;
  }
  .fs-lg-32 {
    font-size: 2rem;
  }
  .fs-lg-36 {
    font-size: 2.25rem;
  }
  .fs-lg-40 {
    font-size: 2.5rem;
  }
  .fs-lg-44 {
    font-size: 2.75rem;
  }
  .fs-lg-48 {
    font-size: 3rem;
  }
  .fs-lg-50 {
    font-size: 3.125rem;
  }
  .fs-lg-54 {
    font-size: 3.375rem;
  }
  .fs-lg-56 {
    font-size: 3.5rem;
  }
  .fs-lg-60 {
    font-size: 3.75rem;
  }
  .fs-lg-64 {
    font-size: 4rem;
  }
}
@media (min-width: 1200px) {
  .fs-xl-10 {
    font-size: 0.625rem;
  }
  .fs-xl-11 {
    font-size: 0.6875rem;
  }
  .fs-xl-12 {
    font-size: 0.75rem;
  }
  .fs-xl-13 {
    font-size: 0.8125rem;
  }
  .fs-xl-14 {
    font-size: 0.875rem;
  }
  .fs-xl-15 {
    font-size: 0.9375rem;
  }
  .fs-xl-16 {
    font-size: 1rem;
  }
  .fs-xl-17 {
    font-size: 1.0625rem;
  }
  .fs-xl-18 {
    font-size: 1.125rem;
  }
  .fs-xl-19 {
    font-size: 1.1875rem;
  }
  .fs-xl-20 {
    font-size: 1.25rem;
  }
  .fs-xl-21 {
    font-size: 1.3125rem;
  }
  .fs-xl-22 {
    font-size: 1.375rem;
  }
  .fs-xl-23 {
    font-size: 1.4375rem;
  }
  .fs-xl-24 {
    font-size: 1.5rem;
  }
  .fs-xl-25 {
    font-size: 1.5625rem;
  }
  .fs-xl-26 {
    font-size: 1.625rem;
  }
  .fs-xl-28 {
    font-size: 1.75rem;
  }
  .fs-xl-32 {
    font-size: 2rem;
  }
  .fs-xl-36 {
    font-size: 2.25rem;
  }
  .fs-xl-40 {
    font-size: 2.5rem;
  }
  .fs-xl-44 {
    font-size: 2.75rem;
  }
  .fs-xl-48 {
    font-size: 3rem;
  }
  .fs-xl-50 {
    font-size: 3.125rem;
  }
  .fs-xl-54 {
    font-size: 3.375rem;
  }
  .fs-xl-56 {
    font-size: 3.5rem;
  }
  .fs-xl-60 {
    font-size: 3.75rem;
  }
  .fs-xl-64 {
    font-size: 4rem;
  }
}
.page-breadcrumbs .experience-breadcrumbsList,
.page-breadcrumbs .experience-assets-breadcrumb,
.page-breadcrumbs .page-breadcrumbs-link {
  display: inline-block;
}
.page-breadcrumbs .experience-assets-breadcrumb:not(:first-of-type) .page-breadcrumbs-link img {
  display: none;
}
.page-breadcrumbs .experience-assets-breadcrumb:not(:first-of-type):last-of-type .breadcrumb-divider {
  display: none;
}
.page-breadcrumbs .breadcrumb-divider {
  margin: 0 0.3125rem;
}